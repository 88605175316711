import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import dayjs from "dayjs";
import { useUpdateEffect } from "react-use";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomerSelector from "@app/components/organisms/CustomerSelector/CustomerSelector";
import OfferDetailTabPanelContent from "@app/components/organisms/RequestDetail/OfferDetailTabPanelContent/OfferDetailTabPanelContent";
import CreateRequestDialog from "@app/components/organisms/CreateRequestDialog/CreateRequestDialog";
import RebookingRequestDialog from "@app/components/organisms/RebookingRequestDialog/RebookingRequestDialog";
import ConfirmationDialog from "@app/components/molecules/ConfirmationDialog/ConfirmationDialog";
import Logo from "@app/components/atoms/Logo/Logo";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import EmptyListMessage from "@app/components/atoms/EmptyListMessage/EmptyListMessage";
import Tabs, {
  Tab,
  TabPanel,
  TabsProps,
} from "@app/components/atoms/Tabs/Tabs";
import Chat from "@app/components/organisms/Chat/Chat";
import { SubmitChatMessageData } from "@app/components/organisms/Chat/ChatForm";

import SimilarRequestsSection, {
  useSimilarRequestSectionProps,
} from "@app/components/organisms/SimilarRequestsSection/SimilarRequestsSection";

import OfferDetailTab, {
  OfferDetailTabProps,
} from "@app/components/organisms/RequestDetail/OfferDetailTab/OfferDetailTab";

import { useDateAndTimeFormats } from "@app/hooks/useDateAndTimeFormats";
import { Actions, OfferStatuses } from "@strafos/common";
import { getOpenOfferId } from "@app/utils/requestUtils";
import { selectRequestsListIsReloading } from "@app/store/pages/requests/requestList/requestList.selectors";
import { postClientAction } from "@app/store/api/clients/clients.actions";
import { postContactPersonAction } from "@app/store/api/contactPersons/contactPersons.actions";
import { useCanUser } from "@app/hooks/useCanUser";
import { selectIsRebookingDialogOpen } from "@app/store/pages/requests/rebooking/rebooking.selectors";
import { setIsRebookingDialogOpenAction } from "@app/store/pages/requests/rebooking/rebooking.actions";
import { selectCreateRequestIsLoading } from "@app/store/pages/requests/createRequest/createRequest.selectors";

import {
  selectCreatedClientId,
  selectIsPostClientLoading,
  selectPostClientError,
} from "@app/store/api/clients/clients.selectors";

import {
  checkOpenOfferDetailStateAction,
  deleteOfferAction,
  getChatAction,
  getRequestDetailAction,
  patchRequestAction,
  postChatMessageAction,
  resetIgnoreLegsFromOptimizationStateAction,
  resetRecalculationStateAction,
  setOpenOfferIdAction,
  updateOfferStatusAction,
} from "@app/store/pages/requests/requestDetail/requestDetail.actions";

import {
  selectChatError,
  selectChatMessages,
  selectIsChatLoading,
  selectIsPatchRequestLoading,
  selectIsRequestDetailLoading,
  selectOpenOfferId,
  selectRequestDetailData,
  selectRequestDetailError,
} from "@app/store/pages/requests/requestDetail/requestDetail.selectors";

import {
  selectCreatedContactPersonId,
  selectIsPostContactPersonLoading,
  selectPostContactPersonError,
} from "@app/store/api/contactPersons/contactPersons.selectors";
import RequestNotes from "@app/components/organisms/RequestNotes/RequestNotes";
import { selectSelectedOperator } from "@app/store/core/userOperators/userOperators.selectors";
import RequestMap from "@app/components/organisms/RequestMap";
import { ScrollToTop } from "@app/components/atoms/ScrollToTop/ScrollToTop";
import { useMutation } from "@tanstack/react-query";
import { api } from "@app/utils/api/api";
import { ChatMessageDto } from "@strafos/common";

const COLLAPSE_ANIMATION_LENGTH = 500;

interface RequestDetailProps {
  requestId: number;
  onCollapseAnimationFinished?: () => void;
  className?: string;
}

const RequestDetail = ({
  requestId,
  onCollapseAnimationFinished,
  className,
}: RequestDetailProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dateTimeFormat } = useDateAndTimeFormats();
  const canUser = useCanUser();

  const similarRequestsSectionProps = useSimilarRequestSectionProps(requestId);

  const canUserDisplayClient = canUser(Actions.GetClient);
  const canUserDisplayContactPerson = canUser(Actions.GetContactPerson);
  const canUserUpdateRequest = canUser(Actions.UpdateRequest);
  const canUserDeleteOffer = canUser(Actions.DeleteOffer);
  const canUserDisplayChat = canUser(Actions.GetChat);
  const canUserSendChatMessage = canUser(Actions.SendChatMessage);

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const [deleteOfferId, setDeleteOfferId] = useState<number | null>(null);

  const [isCollapseAnimationRunning, setIsCollapseAnimationRunning] =
    useState(true);

  const [isCreateRequestDialogOpen, setIsCreateRequestDialogOpen] =
    useState(false);

  const isRebookingDialogOpen = useSelector(selectIsRebookingDialogOpen);

  const isRequestDetailLoading = useSelector(selectIsRequestDetailLoading);
  const requestDetailData = useSelector(selectRequestDetailData);
  const requestDetailError = useSelector(selectRequestDetailError);

  const isPatchRequestLoading = useSelector(selectIsPatchRequestLoading);
  const isListReloading = useSelector(selectRequestsListIsReloading);
  const openOfferId = useSelector(selectOpenOfferId);

  const chatMessages = useSelector(selectChatMessages);
  const isChatLoading = useSelector(selectIsChatLoading);
  const chatError = useSelector(selectChatError);

  const isPostClientLoading = useSelector(selectIsPostClientLoading);
  const postClientError = useSelector(selectPostClientError);
  const postContactPersonError = useSelector(selectPostContactPersonError);
  const createdClientId = useSelector(selectCreatedClientId);
  const createdContactPersonId = useSelector(selectCreatedContactPersonId);

  const isCreatingRequest = useSelector(selectCreateRequestIsLoading);

  const operator = useSelector(selectSelectedOperator);

  const [isMapVisible, setIsMapVisible] = useState(false);
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);
  const mapContainerEl = useRef<HTMLDivElement>(null);

  const [allChatMessages, setAllChatMessages] = useState<ChatMessageDto[]>([]);

  useEffect(() => {
    setAllChatMessages(chatMessages ?? []);
  }, [chatMessages]);

  const isPostContactPersonLoading = useSelector(
    selectIsPostContactPersonLoading,
  );

  const offerIndex = requestDetailData?.offers.findIndex(
    (offer) => offer.id === openOfferId,
  );

  const openOffer = requestDetailData?.offers.find(
    (offer) => offer.id === openOfferId,
  );

  const onSubmitChatMessage = ({
    content,
    offerId,
    nextStatus,
  }: SubmitChatMessageData) => {
    if (offerId && nextStatus) {
      return dispatch(updateOfferStatusAction(offerId, nextStatus, content));
    }

    dispatch(postChatMessageAction({ content, offer_id: offerId }));
  };

  const onAddRequestTabClick = () => {
    setIsCreateRequestDialogOpen(true);
  };

  const onTabChange: TabsProps["onChange"] = (event, nextValue) => {
    const offerId = requestDetailData?.offers[nextValue].id;

    if (!offerId) {
      return;
    }

    dispatch(setOpenOfferIdAction(offerId));

    dispatch(resetIgnoreLegsFromOptimizationStateAction());

    dispatch(resetRecalculationStateAction());
  };

  const onOfferDetailTabClose: OfferDetailTabProps["onClose"] = (id) => {
    setDeleteOfferId(id);
  };

  const onOfferDeleteConfirmation = () => {
    if (!deleteOfferId) {
      return;
    }

    dispatch(deleteOfferAction(deleteOfferId));

    setDeleteOfferId(null);
  };

  const onRebookingButtonClick = () => {
    dispatch(setIsRebookingDialogOpenAction(true));
  };

  const onCustomerSelectorSubmit = ({
    customerId,
    contactPersonId,
  }: {
    customerId: number;
    contactPersonId: number;
  }) => {
    if (!requestDetailData) {
      return;
    }

    dispatch(
      patchRequestAction(requestDetailData.id, {
        client_id: customerId,
        contact_person_id: contactPersonId,
      }),
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCollapseAnimationRunning(false);
      onCollapseAnimationFinished?.();
    }, COLLAPSE_ANIMATION_LENGTH);

    dispatch(getRequestDetailAction(requestId, operator?.id));
    dispatch(getChatAction(requestId));

    return () => {
      setIsCollapseAnimationRunning(true);
      clearTimeout(timeout);
    };
  }, [requestId, operator?.id]);

  const { mutate: markChatMessagesAsReadM } = useMutation({
    mutationFn: api.postMarkChatMessageAsRead,
    onSuccess: () => {
      setAllChatMessages((state) =>
        state.map((m) => ({
          ...m,
          displayed_at: new Date(),
        })),
      );
    },
  });

  useEffect(() => {
    const to = setTimeout(() => {
      if (chatMessages?.some((message) => !message.displayed_at)) {
        const [latestUnreadMessage] = chatMessages
          .filter((message) => !message.displayed_at)
          .sort((a, b) => dayjs.utc(b.created_at).diff(a.created_at));

        if (latestUnreadMessage) {
          markChatMessagesAsReadM({
            requestId: latestUnreadMessage.request_id,
            latestMessageId: latestUnreadMessage.id,
          });
        }
      }
    }, 3_000);

    return () => {
      clearTimeout(to);
    };
  }, [chatMessages]);

  useEffect(() => {
    if (!requestDetailData) {
      return;
    }

    const openOfferExists = requestDetailData.offers.some(
      (offer) => offer.id === openOfferId,
    );

    if (!openOfferExists) {
      dispatch(setOpenOfferIdAction(getOpenOfferId(requestDetailData.offers)));
    }
  }, [requestDetailData?.offers]);

  useEffect(() => {
    if (openOfferId === null || offerIndex === -1) {
      return;
    }

    dispatch(checkOpenOfferDetailStateAction(requestId, openOfferId));
  }, [openOffer?.status, openOfferId, offerIndex]);

  useUpdateEffect(() => {
    if (!requestDetailData || !createdClientId) {
      return;
    }

    if (!isPostClientLoading && !postClientError) {
      dispatch(
        patchRequestAction(requestDetailData.id, {
          client_id: createdClientId,
        }),
      );
    }
  }, [isPostClientLoading, postClientError]);

  useUpdateEffect(() => {
    if (!requestDetailData || !createdContactPersonId) {
      return;
    }

    if (!isPostContactPersonLoading && !postContactPersonError) {
      dispatch(
        patchRequestAction(requestDetailData.id, {
          contact_person_id: createdContactPersonId,
        }),
      );
    }
  }, [isPostContactPersonLoading, postContactPersonError]);

  // scroll into view when map get fullscreen
  useEffect(() => {
    if (!isMapVisible) {
      return;
    }
    mapContainerEl.current?.scrollIntoView({ behavior: "smooth" });
  }, [isMapVisible, isMapFullscreen]);

  if (
    isRequestDetailLoading ||
    requestDetailData?.id !== requestId ||
    isCollapseAnimationRunning ||
    offerIndex === undefined
  ) {
    return (
      <StyledLoadingSpinner loading={true}>
        <StyledLogo />
      </StyledLoadingSpinner>
    );
  }

  if (requestDetailError || !requestDetailData) {
    return (
      <NoDataContainer>
        <EmptyListMessage title={t("molecules.RequestDetail.defaultError")} />
      </NoDataContainer>
    );
  }

  return (
    <>
      <ScrollToTop elementId={requestId} />
      <Box
        display="flex"
        minHeight="75vh"
        position="relative"
        className={className}
      >
        <MapContainer
          $isVisible={isMapVisible}
          $isFullScreen={isMapFullscreen}
          ref={mapContainerEl}
        >
          {isMapVisible && openOfferId && openOffer && (
            <RequestMap
              offer={openOffer}
              show={isMapVisible}
              onFullscreenButtonClick={() =>
                setIsMapFullscreen((state) => !state)
              }
              isFullScreen={isMapFullscreen}
              onHideButtonClick={() => {
                setIsMapFullscreen(false);
                setIsMapVisible(false);
              }}
            />
          )}
        </MapContainer>
        <MainContainer $isSidebarVisible={isSidebarVisible}>
          <Box display="flex" pr="5rem">
            <Tabs
              value={offerIndex}
              onChange={onTabChange}
              variant="scrollable"
              /**
               * @todo [Tech] Why does auto not work?
               */
              scrollButtons={requestDetailData.offers.length > 3 ? "on" : "off"}
            >
              {requestDetailData.offers.map((offer, index) => (
                <Tab
                  key={offer.id}
                  data-testid={`RequestDetail__offer-tab[${index}]`}
                  label={
                    <OfferDetailTab
                      isReserved={!!offer.reserved}
                      isActive={openOfferId === offer.id}
                      id={offer.id}
                      status={offer.status}
                      flag={offer.flag}
                      aircraftCode={offer.aircraft.registration_code}
                      onClose={
                        requestDetailData.offers.length > 1 &&
                        canUserDeleteOffer &&
                        [
                          OfferStatuses.New,
                          OfferStatuses.Unhandled,
                          OfferStatuses.Draft,
                        ].includes(offer.status)
                          ? onOfferDetailTabClose
                          : undefined
                      }
                      subtitle={t("molecules.RequestDetail.offerTabSubtitle", {
                        updatedAt: dayjs(
                          offer.updated_at ?? offer.created_at,
                        ).format(dateTimeFormat),
                      })}
                    />
                  }
                />
              ))}
            </Tabs>
            {requestDetailData.status !== OfferStatuses.Booked &&
              requestDetailData.status !== OfferStatuses.BookedCancelled && (
                <Tab
                  onClick={onAddRequestTabClick}
                  data-testid="RequestDetail__new-offer-tab"
                  label={
                    <Box
                      px={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {isCreatingRequest ? (
                        <StyledCircularProgress />
                      ) : (
                        <StyledAddIcon />
                      )}
                    </Box>
                  }
                />
              )}
          </Box>
          <Box display="flex" flexDirection="column">
            <Box mt={5}>
              {requestDetailData.offers.map((offer, index) => (
                <StyledTabPanel value={offerIndex} index={index} key={offer.id}>
                  <OfferDetailTabPanelContent
                    onRebookingButtonClick={onRebookingButtonClick}
                    offer={offer}
                    request={requestDetailData}
                    onShowMapButtonClick={() => setIsMapVisible(true)}
                  />
                </StyledTabPanel>
              ))}
            </Box>
          </Box>

          <ExpandSidebarIconButton
            $isSidebarVisible={isSidebarVisible}
            onClick={() => setIsSidebarVisible(!isSidebarVisible)}
            title={
              isSidebarVisible
                ? t("organisms.RequestDetail.expandSidebarIconButtonTitle")
                : t("organisms.RequestDetail.collapseSidebarIconButtonTitle")
            }
          >
            <ArrowBackIosIcon />
          </ExpandSidebarIconButton>
        </MainContainer>

        <SidebarContainer $isSidebarVisible={isSidebarVisible}>
          <SimilarRequestsSection {...similarRequestsSectionProps} />

          {canUserDisplayClient && canUserDisplayContactPerson && (
            <StyledCustomerSelector
              customerId={requestDetailData.client_id}
              contactPersonId={requestDetailData.contact_person_id}
              isLoading={isPatchRequestLoading || isListReloading}
              isPostClientLoading={isPostClientLoading}
              isPostContactPersonLoading={isPostContactPersonLoading}
              onSubmit={onCustomerSelectorSubmit}
              onCreateNewClient={(data) => dispatch(postClientAction(data))}
              disabled={!canUserUpdateRequest}
              onCreateNewContactPerson={(data) => {
                if (!requestDetailData.client_id) {
                  throw new Error(
                    "'client_id' is required on request entity before updating 'contact_person_'",
                  );
                }

                dispatch(
                  postContactPersonAction({
                    ...data,
                    client_id: requestDetailData.client_id,
                  }),
                );
              }}
            />
          )}
          {canUserDisplayChat && (
            <StyledChat
              isLoading={isChatLoading}
              error={chatError}
              messages={allChatMessages}
              disabled={!requestDetailData.client_id || !canUserSendChatMessage}
              selectedOffer={openOffer}
              onSubmitMessage={onSubmitChatMessage}
            />
          )}
          <StyledRequestNotes
            notes={requestDetailData.notes || []}
            requestId={requestDetailData.id}
          />
        </SidebarContainer>
      </Box>
      <ConfirmationDialog
        dialogTitle={t("molecules.RequestDetail.deleteConfirmationTitle")}
        dialogContent={t("molecules.RequestDetail.deleteConfirmationContent")}
        open={deleteOfferId !== null}
        onConfirm={onOfferDeleteConfirmation}
        onCancel={() => setDeleteOfferId(null)}
        onClose={() => setDeleteOfferId(null)}
      />
      <CreateRequestDialog
        open={isCreateRequestDialogOpen}
        onClose={() => setIsCreateRequestDialogOpen(false)}
        request={requestDetailData}
      />
      <RebookingRequestDialog
        open={isRebookingDialogOpen}
        onClose={() => dispatch(setIsRebookingDialogOpenAction(false))}
        data={requestDetailData}
      />
    </>
  );
};

const MapContainer = styled.div<{
  $isVisible: boolean;
  $isFullScreen: boolean;
}>`
  position: fixed;
  right: 0;
  top: 0;
  background: white;
  z-index: 100;

  min-width: ${({ $isVisible, $isFullScreen }) =>
    $isFullScreen ? "99%" : $isVisible ? "40%" : "0%"};
  max-width: ${({ $isVisible, $isFullScreen }) =>
    $isFullScreen ? "99%" : $isVisible ? "40%" : "0%"};
  overflow: hidden;
  transition: all 0.5s;
  height: ${({ $isFullScreen }) => ($isFullScreen ? "100vh" : "100vh")};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
`;

const MainContainer = styled.div<{ $isSidebarVisible: boolean }>`
  background: #fff;
  padding: 1.75rem 0 0 0.75rem;
  position: relative;
  transition: width 0.25s;
  z-index: 1;

  width: ${({ $isSidebarVisible }) => ($isSidebarVisible ? "70%" : "100%")};
`;

const SidebarContainer = styled.div<{ $isSidebarVisible: boolean }>`
  align-items: flex-end;
  border-left: 1px solid ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  flex-direction: column;
  right: 0;
  transition: width 0.25s;
  width: ${({ $isSidebarVisible }) => ($isSidebarVisible ? "30%" : "0")};
  overflow: hidden;
  height: 100%;
  background-color: white;
`;

const ExpandSidebarIconButton = styled(IconButton)<{
  $isSidebarVisible: boolean;
}>`
  position: absolute;
  top: 2rem;
  transition: transform 0.25s;
  right: 1rem;

  transform: ${({ $isSidebarVisible }) =>
    $isSidebarVisible && "rotate(180deg)"};

  > span {
    transform: translate(15%, 0px);
  }
`;

const StyledTabPanel = styled(TabPanel)`
  position: relative;
`;

const StyledCustomerSelector = styled(CustomerSelector)`
  width: 100%;
`;

const StyledAddIcon = styled(AddIcon)`
  font-size: 1rem;
`;

const StyledLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: 3rem;
  height: 3rem;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`;

const NoDataContainer = styled.div`
  margin: 4rem auto;
  display: flex;
  justify-content: center;
`;

const StyledChat = styled(Chat)`
  width: 100%;
  height: auto;
  max-height: 40rem;
`;

const StyledRequestNotes = styled(RequestNotes)`
  width: 100%;
  height: auto;
  max-height: 25rem;
`;

const StyledCircularProgress = styled(CircularProgress)`
  width: 1rem !important;
  height: 1rem !important;
`;

export default RequestDetail;
