import produce from "immer";
import { ValidateOptions } from "yup/es/types";
import * as yup from "yup";

import { LegEditorFormData } from "@app/components/organisms/LegEditorForm/LegEditorForm";
import {
  DisplayTimeTypes,
  LegComputationRequest,
  AircraftDetailDto,
  BaseLegDetailDto,
} from "@strafos/common";

import {
  getCombinedLocalDate,
  getISOStringIgnoringTimezone,
  getLocalDateIgnoringTimezone,
  getTimezoneAwareDateFromUTC,
  getUTCFromTimezoneAwareDate,
} from "@app/utils/dateUtils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface ExtendedValidateOptions extends ValidateOptions {
  index: number;
}

interface TestContextWithOptionsAndFrom
  extends Omit<yup.TestContext, "options"> {
  options: ExtendedValidateOptions;
  from: Array<{
    value: LegEditorFormData;
  }>;
}

interface GetLegEditorDefaultValuesOptions {
  timeDisplay: DisplayTimeTypes;
}

interface GetLegEditorTransformedDataOptions {
  timeDisplay: DisplayTimeTypes;
}

export const getLegEditorTransformedData = (
  legEditorData: LegEditorFormData,
  { timeDisplay }: GetLegEditorTransformedDataOptions,
): { requests: LegComputationRequest[] } => {
  const requests = legEditorData.legs.map((leg) => {
    const baseNextLeg: LegComputationRequest = {
      departure_airport_id: leg.departureAirport?.id as number,
      arrival_airport_id: leg.arrivalAirport?.id as number,
      passenger_count: leg.passengerCount,
      type: leg.type,
      airway_id: leg.airwayId ?? null,
    };

    return produce(baseNextLeg, (draft) => {
      if (
        leg.departureDate &&
        leg.departureTime &&
        leg.departureAirport?.timezone
      ) {
        draft.departure_date = getUTCFromTimezoneAwareDate(
          leg.departureDate,
          leg.departureTime,
          leg.departureAirport.timezone,
          timeDisplay,
        );
      }

      if (leg.arrivalDate && leg.arrivalTime && leg.arrivalAirport?.timezone) {
        draft.arrival_date = getUTCFromTimezoneAwareDate(
          leg.arrivalDate,
          leg.arrivalTime,
          leg.arrivalAirport.timezone,
          timeDisplay,
        );
      }
    });
  });

  return { requests };
};

export const getEditorDefaultValues = (
  legs: BaseLegDetailDto[],
  aircraft: AircraftDetailDto,
  { timeDisplay }: GetLegEditorDefaultValuesOptions,
): LegEditorFormData => {
  return {
    legs: legs.map(
      ({
        id,
        departure_date,
        departure_airport,
        arrival_date,
        arrival_airport,
        aircraft_id,
        airway_id,
        passenger_count,
        profit,
        variable_cost,
        other_costs,
        airport_fee,
        handling_fee,
        arrival_fee,
        catering_fee,
        departure_fee,
        distance_in_nautical_miles,
        type,
        duration_in_minutes,
        original_duration_in_minutes,
        remove_leg_id,
        remove_leg,
        offer_id,
        isCabotage,
        warnings,
      }) => {
        const departureDate = getTimezoneAwareDateFromUTC(
          getLocalDateIgnoringTimezone(departure_date),
          departure_airport.timezone,
          timeDisplay,
        );

        const arrivalDate = getTimezoneAwareDateFromUTC(
          getLocalDateIgnoringTimezone(arrival_date),
          arrival_airport.timezone,
          timeDisplay,
        );

        return {
          id: id,
          type: type,
          departureTime: departureDate,
          departureAirport: departure_airport ?? null,
          arrivalTime: arrivalDate,
          arrivalAirport: arrival_airport ?? null,
          passengerCount: passenger_count,
          aircraft,
          departureDate,
          arrivalDate,
          airwayId: airway_id,
          isCabotage,
          warnings,
          extras: {
            profit,
            variable_cost,
            other_costs,
            airport_fee,
            aircraft_id,
            airway_id,
            handling_fee,
            arrival_fee,
            catering_fee,
            departure_fee,
            passenger_count,
            distance_in_nautical_miles,
            duration_in_minutes,
            original_duration_in_minutes,
            type,
            remove_leg_id,
            remove_leg,
            offer_id,
          },
        };
      },
    ),
  };
};

// TODO workaround - there is no native support for searching through nested parents
//  (to see the values of preceding array item in this case)
//  see https://github.com/jquense/yup/issues/289
export const getPrecedingLegValues = (testContext: unknown) => {
  const getIsContextWithOptionsAndFrom = (
    context: unknown,
  ): context is TestContextWithOptionsAndFrom =>
    Number.isFinite(
      (context as TestContextWithOptionsAndFrom)?.options?.index,
    ) && !!(context as TestContextWithOptionsAndFrom).from;

  if (!getIsContextWithOptionsAndFrom(testContext)) {
    return null;
  }

  if (!testContext.options.index) {
    return null;
  }

  return testContext.from[1].value.legs[testContext.options.index - 1];
};

export const getEarliestCombinedDepartureDateISOString = (
  legValues: LegEditorFormData["legs"],
): string | null => {
  const [earliestDepartureDate] = legValues
    .reduce<string[]>((acc, leg) => {
      if (!leg.departureDate || !leg.departureTime) {
        return acc;
      }

      const localDepartureDate = getCombinedLocalDate(
        leg.departureDate,
        leg.departureTime,
      );

      const departureDateIgnoringTimezone =
        getISOStringIgnoringTimezone(localDepartureDate);

      return [...acc, departureDateIgnoringTimezone];
    }, [])
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return earliestDepartureDate ?? null;
};

export const getLatestCombinedArrivalDateISOString = (
  legValues: LegEditorFormData["legs"],
) => {
  const [latestArrivalDate] = legValues
    .reduce<string[]>((acc, leg) => {
      if (!leg.arrivalDate || !leg.arrivalTime) {
        return acc;
      }

      const localArrivalDate = getCombinedLocalDate(
        leg.arrivalDate,
        leg.arrivalTime,
      );

      const arrivalDateIgnoringTimezone =
        getISOStringIgnoringTimezone(localArrivalDate);

      return [...acc, arrivalDateIgnoringTimezone];
    }, [])
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  return latestArrivalDate;
};

export const getDepartureDate = (
  date: Date,
  time: Date,
  timezone: string,
  timeDisplay: DisplayTimeTypes,
) => {
  // Convert departure time to UTC
  const departureDateInUTC = getUTCFromTimezoneAwareDate(
    date,
    time,
    timezone,
    timeDisplay,
  );

  // Convert from UTC if applicable
  const departureDate = getTimezoneAwareDateFromUTC(
    getLocalDateIgnoringTimezone(departureDateInUTC),
    timezone,
    timeDisplay,
  );

  return departureDate;
};

export const getArrivalDate = (
  departureDate: Date,
  departureTime: Date,
  departureTimezone: string,
  arrivalTimezone: string,
  timeDisplay: DisplayTimeTypes,
  duration: number,
) => {
  const departureDateInUTC = getUTCFromTimezoneAwareDate(
    departureDate,
    departureTime,
    departureTimezone,
    timeDisplay,
  );
  // Add flight time to departure to calculate the arrival
  const arrivalDate = dayjs(departureDateInUTC)
    .add(duration, "minutes")
    .toDate();

  // Convert from UTC if applicable
  const newArrivalDate = getTimezoneAwareDateFromUTC(
    getLocalDateIgnoringTimezone(arrivalDate),
    arrivalTimezone,
    timeDisplay,
  );

  return newArrivalDate;
};
