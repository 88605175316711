import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Typography from "@app/components/atoms/Typography/Typography";
import Button from "@app/components/atoms/Button/Button";

import { ClientDetailDto, ContactPersonDetailDto } from "@strafos/common";
import Box from "@material-ui/core/Box";
import TextField from "@app/components/atoms/TextField/TextField";
import { api } from "@app/utils/api/api";

export interface CustomerSelectorSelectedStateProps {
  customer?: ClientDetailDto;
  contactPerson?: ContactPersonDetailDto;
  onChangeCustomerClick: () => void;
  className?: string;
  disabled?: boolean;
}

const CustomerSelectorSelectedState = ({
  customer,
  contactPerson,
  onChangeCustomerClick,
  className,
  disabled,
}: CustomerSelectorSelectedStateProps): JSX.Element => {
  const [customerNote, setCustomerNote] = useState(customer?.note || "");
  const [debouncedNote, setDebouncedNote] = useState(customer?.note || "");
  const { t } = useTranslation();

  const handleCustomerNoteChange = (newNote: string) => {
    if (customer && customer.note !== newNote) {
      api.updateClient(customer.id, { note: debouncedNote });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedNote(customerNote);
    }, 1000);

    return () => clearTimeout(timer);
  }, [customerNote]);

  useEffect(() => {
    handleCustomerNoteChange(debouncedNote);
  }, [debouncedNote]);

  return (
    <Container className={className}>
      <Heading>{t("molecules.CustomerSelectorSelectedState.heading")}</Heading>
      <Content>
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-start"
          flexDirection="row"
        >
          <StyledBox
            width={8}
            bgcolor={customer?.color || "transparent"}
            marginRight={0.8}
            height="25px"
          />
          <div>
            {customer && (
              <InfoBlock>
                <Typography>{customer.company_name}</Typography>
                <SmallText>{customer.street}</SmallText>
                <SmallText>{customer.city}</SmallText>
              </InfoBlock>
            )}
            {contactPerson && (
              <InfoBlock>
                <Typography>{contactPerson.name}</Typography>
                <SmallText>{contactPerson.email}</SmallText>
                <SmallText>{contactPerson.phone}</SmallText>
              </InfoBlock>
            )}
          </div>
        </Box>

        <form>
          <TextField
            value={customerNote}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setCustomerNote(e.target.value);
            }}
            placeholder={t("organisms.RequestNote.placeholder")}
            rows={2}
            multiline
            fullWidth
          />
        </form>

        <Controls>
          <Button
            inverted
            onClick={onChangeCustomerClick}
            disabled={disabled}
            data-testid="CustomerSelectorSelectedState__change-customer-button"
          >
            {t("molecules.CustomerSelectorSelectedState.actionButtonText")}
          </Button>
        </Controls>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
`;

const StyledBox = styled(Box)`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const Heading = styled(Typography)`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const SmallText = styled(Typography)`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.grey[400]};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const InfoBlock = styled.div`
  &:first-child {
    margin-bottom: 1rem;
  }
`;

const Controls = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`;

export default CustomerSelectorSelectedState;
